import Cut_Video from '../assets/icon/Cut Video.svg';
import Add_Text from '../assets/icon/Add Text.svg';
import Add_Music from '../assets/icon/Add Music.svg';
import ReverseVideoIcon from '../assets/icon/ReverseVideo.svg';
import FreezeFrameIcon from '../assets/icon/FreezeFrame.svg';
import Chromakey from '../assets/icon/Chromakey.svg';
import EffectSvg from '../assets/icon/effect.svg';
import Record_Screen from '../assets/icon/Record Screen.svg';
import CurveSpeed from '../assets/icon/curve-speed.svg';
import Video_to_Gif from '../assets/icon/Video to GIF.svg';
import Remove_Background from '../assets/icon/Remove Background.svg';
import arrow from '../assets/icon/arrow.svg';
import FJConfig from '../config/FJConfig';
import TrimSvg from '../assets/icon/trim.svg';
import TimelineSvg from '../assets/icon/timeline.svg';
import ElementSvg from '../assets/icon/element.svg';
import CollaborateSvg from '../assets/icon/Collaborate.svg';
import Cloud1Svg from '../assets/icon/cloud-1.svg';
import HDVSvg from '../assets/icon/4K.svg';
import languageData from './language/FJHomeRevision.language';
const language = languageData.language;
const languageList = ['en', 'cn', 'de', 'es', 'fr', 'it', 'jp', 'pt', 'tw', 'id', 'ar'];
const __getChoiceNessToolListByLang = (lang = 'en') => {
  return [
    {
      icon: `${FJConfig.staticResourceServerAddress}/pages/home/choiceness/icon-AIVideoGenerator.webp?v=${FJConfig.buildVersion}`,
      className: 'AIVideoGenerator',
      iconType: 'image',
      name: language[lang]['AI-video-generator'],
      desc: language[lang]['generate-videos-from-your-prompt-article-or-URL'],
      'button-1': language[lang]['generate-video'],
      'input-desc': '5 Techniques for Improving Videos',
    },
    {
      icon: `${FJConfig.staticResourceServerAddress}/pages/home/choiceness/icon-TextToSpeech.webp?v=${FJConfig.buildVersion}`,
      className: 'TextToSpeech',
      iconType: 'image',
      name: language[lang]['AI-text-to-speech'],
      desc: language[lang]['turn-text-into-natural-sounding-voices'],
      'button-1': language[lang]['generate-speech'],
      'input-desc': 'Type or paste your text to generate voices',
    },
    {
      icon: `${FJConfig.staticResourceServerAddress}/pages/home/choiceness/icon-AIScript.webp?v=${FJConfig.buildVersion}`,
      className: 'AIScript',
      iconType: 'image',
      name: language[lang]['AI-script'],
      desc: language[lang]['generate-scripts-for-any-purpose'],
      'button-1': language[lang]['try-AI-script'],
      'input-desc': 'Describe the script you want to generate',
    },
    {
      icon: `${FJConfig.staticResourceServerAddress}/pages/home/choiceness/icon-AIBGRemover.webp?v=${FJConfig.buildVersion}`,
      className: 'AIBGRemover',
      iconType: 'image',
      name: language[lang]['AI-bg-remover'],
      desc: language[lang]['easily-remove-background-from-videos-or-images'],
      'button-1': language[lang]['remove-background'],
    },
    {
      icon: `${FJConfig.staticResourceServerAddress}/pages/home/choiceness/icon-AutoSubtitle.webp?v=${FJConfig.buildVersion}`,
      className: 'AutoSubtitle',
      iconType: 'image',
      name: language[lang]['AI-auto-subtitle'],
      desc: language[lang]['automatically-identify-and-generate-subtitles-through-AI'],
      'button-1': language[lang]['generate-subtitles'],
      'input-desc': 'Videos are a great way to explain your product.',
    },
    {
      icon: `${FJConfig.staticResourceServerAddress}/pages/home/choiceness/Translate-icon.webp?v=${FJConfig.buildVersion}`,
      className: 'CurveSpeed',
      iconType: 'image',
      name: language[lang]['AI-translator'],
      desc: language[lang]['create-multi-language-videos-with-ease'],
      'button-1': language[lang]['translate-now'],
    },
  ];
};

const __getResourceIntroductionListByLang = (lang = 'en') => {
  return [
    {
      number: '4M+',
      from: 0,
      to: 4,
      unit: 'M+',
      duration: 2000,
      desc: language[lang]['videos-and-photos'],
      style: { background: 'linear-gradient(135deg,#fff7f1 1%, #fae9ef)' },
      video: FJConfig.staticResourceServerAddress + '/pages/home/resources/resource1.mp4?v=' + FJConfig.buildVersion,
    },
    {
      number: '74,000+',
      from: 0,
      to: 74000,
      duration: 2000,
      desc: language[lang]['music-and-sound-effects'],
      style: { background: 'linear-gradient(134deg,#f3f8ff 2%, #e0e8fe 99%)' },
      video: FJConfig.staticResourceServerAddress + '/pages/home/resources/resource2.mp4?v=' + FJConfig.buildVersion,
    },
    {
      number: '1000+',
      from: 0,
      to: 1000,
      duration: 2000,
      desc: language[lang]['text-presets'],
      style: { background: 'linear-gradient(134deg,#efffed 2%, #d2f1cf 99%)' },
      video: FJConfig.staticResourceServerAddress + '/pages/home/resources/resource3.mp4?v=' + FJConfig.buildVersion,
    },
    {
      number: '3M+',
      from: 0,
      to: 3,
      unit: 'M+',
      duration: 2000,
      desc: language[lang]['dynamic-elements'],
      style: { background: 'linear-gradient(135deg,#eef8f7 1%, #e0fefd)' },
      video: FJConfig.staticResourceServerAddress + '/pages/home/resources/resource4.mp4?v=' + FJConfig.buildVersion,
    },
    {
      number: '700+',
      from: 0,
      to: 700,
      duration: 2000,
      desc: language[lang]['effects-and-filters'],
      style: { background: 'linear-gradient(134deg,#f9f3fd 1%, #ead0fd 99%)' },
      video: FJConfig.staticResourceServerAddress + '/pages/home/resources/resource5.mp4?v=' + FJConfig.buildVersion,
    },
    {
      number: '200+',
      from: 0,
      to: 200,
      duration: 2000,
      desc: language[lang]['transitions'],
      style: { background: 'linear-gradient(134deg,#fff9eb 1%, #fef5e0 99%)' },
      video: FJConfig.staticResourceServerAddress + '/pages/home/resources/resource6.mp4?v=' + FJConfig.buildVersion,
    },
  ];
};

const __getFeatureListByLang = (lang = 'en') => {
  return [
    {
      title: language[lang]['all-powerful-video-tools-you-need'],
      desc: [
        {
          icon: TrimSvg,
          desc: language[lang]['quickly-trim,-merge-reverse'],
        },
        {
          icon: TimelineSvg,
          desc: language[lang]['keep-audiences-engaged'],
        },
        { icon: ElementSvg, desc: language[lang]['animate-the-elements'] },
      ],
      btnText: language[lang]['get-started'],
      imgUrl: FJConfig.staticResourceServerAddress + '/pages/home/feature/feature1-1.webp',
      target: '/editor/app?ratio=landscape',
    },
    {
      title: language[lang]['collaborate-and-share-seamlessly'],
      desc: [
        {
          icon: CollaborateSvg,
          desc: language[lang]['collaborate-with-teammates-to-work-efficiently-and-build-branding-assets'],
        },
        {
          icon: Cloud1Svg,
          desc: language[lang]['cloud-storage-gives-smooth-access-to-your-files-and-designs'],
        },
        { icon: HDVSvg, desc: language[lang]['export-videos-up-to'] },
      ],
      btnText: language[lang]['get-started'],
      imgUrl: FJConfig.staticResourceServerAddress + '/pages/home/feature/feature2-3.webp',
      target: '/editor/app?ratio=landscape',
    },
  ];
};

const __getUIDataInfo = () => {
  const tempInfo = {};
  languageList.forEach(lang => {
    tempInfo[lang] = {
      'choiceness-tools': __getChoiceNessToolListByLang(lang),
      'resource-introduction-list': __getResourceIntroductionListByLang(lang),
      feature: __getFeatureListByLang(lang),
    };
  });
  return tempInfo;
};

export default {
  language,
  videoToolList: {
    title: 'home-video-tool-title-all-in-one',
    toolList: [
      {
        icon: Cut_Video,
        name: 'Trim/Cut Video',
        ealogName: 'Trim/Cut Video',
        url: 'tools/trim-video/',
        hasLocalization: true,
      },
      {
        icon: Add_Text,
        name: 'Add Text',
        ealogName: 'Add Text',
        url: 'tools/add-text-to-video/',
        hasLocalization: true,
      },
      {
        icon: Add_Music,
        name: 'Add Music',
        ealogName: 'Add Music',
        url: 'tools/add-music-to-video/',
        hasLocalization: true,
      },
      {
        icon: ReverseVideoIcon,
        name: 'Reverse Video',
        ealogName: 'Reverse Video',
        url: 'tools/reverse-video/',
        hasLocalization: true,
      },
      {
        icon: FreezeFrameIcon,
        name: 'Freeze Frame',
        ealogName: 'Freeze Frame',
        url: 'tools/freeze-frame/',
        hasLocalization: true,
      },

      {
        icon: Chromakey,
        name: 'Chroma Key',
        ealogName: 'Chroma key',
        url: 'tools/chroma-key/',
        hasLocalization: true,
      },
      {
        icon: EffectSvg,
        name: 'Video Effects',
        ealogName: 'Video Effects',
        url: 'tools/video-effects/',
        hasLocalization: true,
      },
      {
        icon: Record_Screen,
        name: 'Record Screen',
        ealogName: 'Record Screen',
        url: 'tools/screen-recorder/',
        hasLocalization: true,
      },

      {
        icon: CurveSpeed,
        name: 'Speed Curve',
        ealogName: 'Speed Curve',
        url: 'tools/speed-curve/',
        hasLocalization: true,
      },
      {
        icon: Video_to_Gif,
        name: 'GIF-maker',
        ealogName: 'GIF Maker',
        url: 'tools/gif-maker/',
        hasLocalization: true,
      },
      {
        icon: Remove_Background,
        name: 'Remove Background',
        ealogName: 'Remove Background',
        url: 'tools/remove-background/',
        hasLocalization: true,
      },
      {
        icon: arrow,
        name: 'Explore More Editing Tools',
        ealogName: 'Explore More Editing Tools',
        url: 'tools/',
        hasLocalization: true,
        is_end: '1',
      },
    ],
  },
  UIDataInfo: __getUIDataInfo(),
};
