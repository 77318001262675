export default {
  language: {
    en: {
      'home-banner-desc': 'Create smarter and faster with AI tools and tons of resources.',
      'create-by-template': 'Try a Template',
      'trusted-by': 'TRUSTED BY',

      'home-filter-template-title': 'Start Fast with <span>6000+</span> Templates',

      'template-bottom-text': 'See All',

      'choiceness-title': 'Revolutionize Video Creation with AI Tools',

      'resource-introduction-title': 'Level Up Your Video Content with Rich Resources',
      'resource-introduction-icon': ['One-click Application', 'For Commercial Use', 'Constantly Updated'],

      'all-powerful-video-tools-you-need': 'All Powerful Video Tools You Need',
      'quickly-trim,-merge-reverse': 'Quickly <b>trim, merge, reverse, and speed up or slow down</b> your video clips',
      'keep-audiences-engaged': '<b>Keep audiences engaged</b> by adding text, subtitles, music, voiceover, etc.',
      'animate-the-elements': '<b>Animate the elements</b> in the project and make your video flow.',
      'get-started': 'Get Started',
      'collaborate-and-share-seamlessly': 'Collaborate and Share Seamlessly',
      'collaborate-with-teammates-to-work-efficiently-and-build-branding-assets':
        '<b>Collaborate</b> with teammates to work efficiently and build branding assets.',
      'cloud-storage-gives-smooth-access-to-your-files-and-designs':
        '<b>Cloud storage</b> gives smooth access to your files and designs.',
      'export-videos-up-to': 'Export videos up to <b>4K resolution</b> and directly share to social platforms.',

      'comment-title': 'Trusted by Millions',

      'read-full-story': 'Read full story',
      'case-studies-title': 'Latest Articles',

      'float-entrance-1': 'Try FlexClip to explore ',
      'float-entrance-2': 'AI tools ',
      'float-entrance-3': 'and tons of ',
      'float-entrance-4': 'resources',

      'entrance-title': 'Your journey to stunning videos begins here',

      'AI-video-generator': 'AI Video Generator',
      'generate-videos-from-your-prompt-article-or-URL': 'Generate videos from your prompt, article, or URL',
      'generate-video': 'Generate Video',
      'AI-text-to-speech': 'AI Text to Speech',
      'turn-text-into-natural-sounding-voices': 'Turn text into natural-sounding voices',
      'generate-speech': 'Generate Speech',
      'AI-script': 'AI Script',
      'generate-scripts-for-any-purpose': 'Generate scripts for any purpose',
      'try-AI-script': 'Try AI Script',
      'AI-bg-remover': 'AI BG Remover',
      'easily-remove-background-from-videos-or-images': 'Easily remove background from videos or images',
      'remove-background': 'Remove Background',
      'AI-auto-subtitle': 'AI Auto Subtitle',
      'automatically-identify-and-generate-subtitles-through-AI':
        'Automatically identify and generate subtitles through AI',
      'generate-subtitles': 'Generate Subtitles',
      'AI-translator': 'AI Translator',
      'create-multi-language-videos-with-ease': 'Create multi-language videos with ease',
      'translate-now': 'Translate Now',
      'videos-and-photos': 'Videos & Photos',
      'music-and-sound-effects': 'Music & Sound Effects',
      'text-presets': 'Text Presets',
      'dynamic-elements': 'Dynamic Elements',
      'effects-and-filters': 'Effects & Filters',
      transitions: 'Transitions',
    },
    cn: {
      'home-banner-desc': '运用AI工具和海量资源，更智能、更迅捷地创作。',
      'create-by-template': '从模板开始',
      'trusted-by': 'TRUSTED BY',

      'home-filter-template-title': '从<span>6000+</span>的模板中快速开始',

      'template-bottom-text': '全部模板',

      'choiceness-title': '用AI工具革新视频创作',

      'resource-introduction-title': '用丰富的资源升级您的视频内容',
      'resource-introduction-icon': ['一键应用', '商业用途', '资源不断上新'],

      'all-powerful-video-tools-you-need': '您需要的强大视频工具一应俱全',
      'quickly-trim,-merge-reverse': '快速<b>裁剪、合并、倒放，加快或减慢</b>您的视频片段。',
      'keep-audiences-engaged': '通过添加文字、字幕、音乐、旁白等，<b>保持观众的参与度。</b>',
      'animate-the-elements': '将项目中的<b>元素动态化</b>，使您的视频流畅顺滑。',
      'get-started': '现在开始',
      'collaborate-and-share-seamlessly': '无缝的协作与分享',
      'collaborate-with-teammates-to-work-efficiently-and-build-branding-assets':
        '与团队成员<b>紧密协作</b>，提高工作效率并筑建品牌资产。',
      'cloud-storage-gives-smooth-access-to-your-files-and-designs': '<b>云存储技术</b>助您自由地访问文件和设计。',
      'export-videos-up-to': '导出高达<b>4K分辨率</b>的视频，并一键分享到社交平台。',

      'comment-title': '受到数百万的内容创作者信赖',

      'read-full-story': '阅读完整故事',
      'case-studies-title': '最近文章',

      'float-entrance-1': '探索FlexClip，体验智能',
      'float-entrance-2': 'AI工具',
      'float-entrance-3': '和',
      'float-entrance-4': '海量资源',

      'entrance-title': '您的精彩视频之旅由此开始',

      'AI-video-generator': 'AI视频生成器',
      'generate-videos-from-your-prompt-article-or-URL': '根据提示、文章内容或者URL生成视频',
      'generate-video': '生成视频',
      'AI-text-to-speech': 'AI文字转语音',
      'turn-text-into-natural-sounding-voices': '将文本生成自然人声',
      'generate-speech': '生成语音',
      'AI-script': 'AI视频脚本',
      'generate-scripts-for-any-purpose': '根据您的想法生成视频脚本',
      'try-AI-script': '试用AI脚本',
      'AI-bg-remover': 'AI背景移除',
      'easily-remove-background-from-videos-or-images': '轻松去除视频或图片背景',
      'remove-background': '移除背景',
      'AI-auto-subtitle': 'AI自动字幕',
      'automatically-identify-and-generate-subtitles-through-AI': '通过AI自动识别和生成字幕',
      'generate-subtitles': '生成字幕',
      'AI-translator': 'AI翻译',
      'create-multi-language-videos-with-ease': '轻松制作多语言视频',
      'translate-now': '即刻翻译',
      'videos-and-photos': '视频&图片',
      'music-and-sound-effects': '音乐&音效',
      'text-presets': '文字预设',
      'dynamic-elements': '动态元素',
      'effects-and-filters': '特效&滤镜',
      transitions: '转场',
    },
    de: {
      'home-banner-desc': 'Erstellen Sie intelligenter und schneller mit KI-Tools und einer Vielzahl von Ressourcen.',
      'create-by-template': 'Vorlage ausprobieren',
      'trusted-by': 'TRUSTED BY',

      'home-filter-template-title': 'Schneller Einstieg mit <span>6000+</span> Vorlagen',

      'template-bottom-text': 'Alles',

      'choiceness-title': 'Revolutionieren Sie die Videoproduktion mit KI-Tools',

      'resource-introduction-title': 'Verbessern Sie Ihren Videoinhalt mit reichhaltigen Ressourcen',
      'resource-introduction-icon': ['Ein-Klick-Anwendung', 'Für kommerzielle Nutzung', 'Ständig aktualisiert'],

      'all-powerful-video-tools-you-need': 'Alle leistungsstarken Videowerkzeuge, die Sie benötigen',
      'quickly-trim,-merge-reverse':
        'Schnell <b>schneiden, zusammenführen, umkehren und erhöhen oder verlangsamen</b> das Tempo Ihrer Videoclips.',
      'keep-audiences-engaged':
        '<b>Halten Sie das Publikum</b> durch Hinzufügen von Text, Untertiteln, Musik, Voiceover usw engagiert.',
      'animate-the-elements': '<b>Animieren Sie die Elemente</b> im Projekt und lassen Sie Ihr Video fließen.',
      'get-started': 'Loslegen',
      'collaborate-and-share-seamlessly': 'Nahtlose Zusammenarbeit und Teilen',
      'collaborate-with-teammates-to-work-efficiently-and-build-branding-assets':
        '<b>Arbeiten Sie zusammen</b> mit Teammitgliedern, um effizient zu arbeiten und Markenassets aufzubauen.',
      'cloud-storage-gives-smooth-access-to-your-files-and-designs':
        '<b>Der Cloud-Speicher</b> gewährt einen reibungslosen Zugriff auf Ihre Dateien und Designs.',
      'export-videos-up-to':
        'Exportieren Sie Videos mit einer <b>Auflösung von bis zu 4K</b> und teilen Sie sie direkt auf sozialen Plattformen.',

      'comment-title': 'Vertraut von Millionen von Inhaltserstellern',

      'read-full-story': 'Vollständige Geschichte lesen',
      'case-studies-title': 'Neueste Artikel',

      'float-entrance-1': 'Probieren Sie FlexClip aus, um ',
      'float-entrance-2': 'KI-Tools',
      'float-entrance-3': ' und ',
      'float-entrance-4': 'eine Vielzahl von Ressourcen zu erkunden',

      'entrance-title': 'Ihre Reise zu atemberaubenden Videos beginnt hier',

      'AI-video-generator': 'KI Video Generator',
      'generate-videos-from-your-prompt-article-or-URL':
        'Erstellen Sie Videos aus Ihrer Prompt-Eingabe, Ihrem Artikel oder Ihrer URL',
      'generate-video': 'Video generieren',
      'AI-text-to-speech': 'KI Text zu Sprache',
      'turn-text-into-natural-sounding-voices': 'Verwandeln Sie Text in natürlich klingende Stimmen',
      'generate-speech': 'Sprache generieren',
      'AI-script': 'KI Videoskript',
      'generate-scripts-for-any-purpose': 'Generieren Sie Skripte für jeden Zweck',
      'try-AI-script': 'Versuchen KI Videoskript',
      'AI-bg-remover': 'KI-Hintergrundentferner',
      'easily-remove-background-from-videos-or-images': 'Entfernen Sie einfach den Hintergrund von Videos oder Bildern',
      'remove-background': 'Hintergrund entfernen',
      'AI-auto-subtitle': 'KI Automatische Untertitel',
      'automatically-identify-and-generate-subtitles-through-AI':
        'Automatische Identifizierung und Generierung von Untertiteln durch KI',
      'generate-subtitles': 'Untertitel generieren',
      'AI-translator': 'KI-Übersetzer',
      'create-multi-language-videos-with-ease': 'Erstellen Sie mehrsprachige Videos mit Leichtigkeit',
      'translate-now': 'Übersetzen jetzt',
      'videos-and-photos': 'Videos & Fotos',
      'music-and-sound-effects': 'Musik & Soundeffekte',
      'text-presets': 'Textvorgaben',
      'dynamic-elements': 'Dynamische Elemente',
      'effects-and-filters': 'Effekte & Filter',
      transitions: 'Übergänge',
    },
    es: {
      'home-banner-desc': 'Crea de manera más inteligente y rápida con herramientas de IA y montones de recursos.',
      'create-by-template': 'Prueba una plantilla',
      'trusted-by': 'TRUSTED BY',

      'home-filter-template-title': 'Empieza rápido con más de  <span>6000+</span> plantillas',

      'template-bottom-text': 'Todas',

      'choiceness-title': 'Revoluciona la creación de vídeos con las herramientas de IA',

      'resource-introduction-title': 'Sube el nivel de tu contenido en vídeo con amplios recursos',
      'resource-introduction-icon': ['Aplicación de un clic', 'Para uso comercial', 'Con actualizaciones constantes'],

      'all-powerful-video-tools-you-need': 'Todas las potentes herramientas de vídeo que necesitas',
      'quickly-trim,-merge-reverse':
        '<b>Recorta, combina, invierte y acelera o decelera</b> tus clips de vídeo de manera rápida.',
      'keep-audiences-engaged':
        '<b>Mantén al público entusiasmado</b> añadiendo texto, subtítulos, música, voz en off, etc.',
      'animate-the-elements': '<b>Anima los elementos</b> del proyecto y haz que tus vídeos fluyan.',
      'get-started': 'Empezar',
      'collaborate-and-share-seamlessly': 'Colabora y comparte fácilmente',
      'collaborate-with-teammates-to-work-efficiently-and-build-branding-assets':
        '<b>Colabora</b> con tus compañeros de equipo para trabajar eficientemente y crear recursos de marca.',
      'cloud-storage-gives-smooth-access-to-your-files-and-designs':
        '<b>El almacenamiento en la nube</b> te proporciona acceso fácil a tus archivos y diseños.',
      'export-videos-up-to':
        'Exporta tus vídeos hasta en <b>resolución 4K</b> y compártelos directamente en las redes sociales.',

      'comment-title': 'Usado por millones de creadores de contenido',

      'read-full-story': 'Lee la historia completa',
      'case-studies-title': 'Últimos artículos',

      'float-entrance-1': 'Prueba FlexClip para explorar ',
      'float-entrance-2': 'herramientas de IA',
      'float-entrance-3': ' y ',
      'float-entrance-4': 'montones de recursos',

      'entrance-title': 'Tu viaje hacia la creación de vídeos asombrosos empieza aquí',

      'AI-video-generator': 'Generador de vídeo IA',
      'generate-videos-from-your-prompt-article-or-URL': 'Genera vídeos a partir de tus indicaciones, artículos o URL',
      'generate-video': 'Genera vídeo',
      'AI-text-to-speech': 'IA Texto a voz',
      'turn-text-into-natural-sounding-voices': 'Transforma el texto en voces naturales',
      'generate-speech': 'Generar Discurso',
      'AI-script': 'Guion de vídeo IA',
      'generate-scripts-for-any-purpose': 'Genera guiones para cualquier propósito',
      'try-AI-script': 'Prueba el guion de IA',
      'AI-bg-remover': 'Eliminador de fondo con IA',
      'easily-remove-background-from-videos-or-images': 'Elimina fácilmente el fondo de vídeos e imágenes',
      'remove-background': 'Elimina el fondo',
      'AI-auto-subtitle': 'Subtítulo automático mediante IA',
      'automatically-identify-and-generate-subtitles-through-AI':
        'Identifica y genera subtítulos automáticamente mediante IA',
      'generate-subtitles': 'Genera subtítulos',
      'AI-translator': 'Traductor IA',
      'create-multi-language-videos-with-ease': 'Crea vídeos en múltiples idiomas con facilidad',
      'translate-now': 'Traducir ahora',
      'videos-and-photos': 'Vídeos y fotos',
      'music-and-sound-effects': 'Música y efectos sonoros',
      'text-presets': 'Preajustes de texto',
      'dynamic-elements': 'Elementos dinámicos',
      'effects-and-filters': 'Efectos y filtros',
      transitions: 'Transiciones',
    },
    fr: {
      'home-banner-desc':
        'Créez de manière plus intelligente et plus rapide avec les outils IA et de nombreuses ressources.',
      'create-by-template': 'Essayez un Modèle',
      'trusted-by': 'TRUSTED BY',

      'home-filter-template-title': 'Commencez Vite avec plus de <span>6000</span> Modèles',

      'template-bottom-text': 'Tout voir',

      'choiceness-title': 'Révolutionnez la Création de Vidéos avec les Outils IA',

      'resource-introduction-title': 'Rehaussez le Contenu de Vos Vidéos avec des Ressources Riches',
      'resource-introduction-icon': ['Application en un Clic', 'Pour un Usage Commercial', 'Mise à Jour Continue'],

      'all-powerful-video-tools-you-need': 'Tous les Outils Vidéo Puissants Dont Vous Avez Besoin',
      'quickly-trim,-merge-reverse':
        '<b>Raccourcissez, fusionnez, inversez, et accélérez</b> ou ralentissez rapidement vos clips vidéo.',
      'keep-audiences-engaged':
        '<b>Gardez votre public engagé</b> en ajoutant du texte, des sous-titres, de la musique, des voix off, etc.',
      'animate-the-elements': '<b>Animez les éléments</b> du projet et donnez à votre vidéo un flux.',
      'get-started': 'Commencer',
      'collaborate-and-share-seamlessly': 'Collaborez et Partagez en Toute Transparence',
      'collaborate-with-teammates-to-work-efficiently-and-build-branding-assets':
        '<b>Collaborez</b> avec vos coéquipiers pour travailler efficacement et créer des éléments de marque.',
      'cloud-storage-gives-smooth-access-to-your-files-and-designs':
        '<b>Le stockage cloud</b> permet un accès fluide à vos fichiers et conceptions.',
      'export-videos-up-to':
        "Exportez des vidéos jusqu'à une <b>résolution 4K</b> et partagez-les directement sur les plateformes sociales.",

      'comment-title': 'Fait Confiance par des Millions de Créateurs de Contenu',

      'read-full-story': "Lire l'Histoire Complète",
      'case-studies-title': 'Derniers Articles',

      'float-entrance-1': 'Essayez FlexClip pour explorer ',
      'float-entrance-2': 'les outils IA ',
      'float-entrance-3': 'et de nombreuses ',
      'float-entrance-4': 'ressources',

      'entrance-title': 'Votre voyage vers des vidéos époustouflantes commence ici',

      'AI-video-generator': 'Générateur de Vidéos IA',
      'generate-videos-from-your-prompt-article-or-URL':
        "Générez des vidéos à partir d'un message, d'un article ou d'une URL",
      'generate-video': 'Génèrez Des Vidéos',
      'AI-text-to-speech': 'Text to Speech IA',
      'turn-text-into-natural-sounding-voices': 'Transformez du texte en voix naturelles',
      'generate-speech': 'Générer de la Voix',
      'AI-script': 'Script Vidéo IA',
      'generate-scripts-for-any-purpose': "Générez des scripts pour n'importe quel usage",
      'try-AI-script': 'Essayez le script AI',
      'AI-bg-remover': "Suppression de l'arrière-plan AI",
      'easily-remove-background-from-videos-or-images': "Supprimez Facilement l'Arrière-plan des Vidéos ou des Images",
      'remove-background': "Supprimer l'Arrière-plan",
      'AI-auto-subtitle': 'Sous-titre auto par IA',
      'automatically-identify-and-generate-subtitles-through-AI':
        "Identifier et générer automatiquement des sous-titres grâce à l'IA",
      'generate-subtitles': 'Générer des Sous-titres',
      'AI-translator': 'Traducteur par IA',
      'create-multi-language-videos-with-ease': 'Créez facilement des vidéos multilingues',
      'translate-now': 'Traduire maintenant',
      'videos-and-photos': 'Vidéos & Photos',
      'music-and-sound-effects': 'Musique & Effets Sonores',
      'text-presets': 'Préréglages de Texte',
      'dynamic-elements': 'Éléments Dynamiques',
      'effects-and-filters': 'Effets & Filtres',
      transitions: 'Transitions',
    },
    it: {
      'home-banner-desc': 'Crea in modo più intelligente e veloce con gli strumenti AI e tonnellate di risorse.',
      'create-by-template': 'Prova un modello',
      'trusted-by': 'TRUSTED BY',

      'home-filter-template-title': 'Inizia velocemente con oltre <span>6000</span> modelli',

      'template-bottom-text': 'Tout voir',

      'choiceness-title': 'Rivoluziona la creazione di Video con gli strumenti IA',

      'resource-introduction-title': 'Migliora il contenuto del tuo video con risorse ricche',
      'resource-introduction-icon': ['Applicazione One-click', 'Per Uso Commerciale', 'Aggiornato costantemente'],

      'all-powerful-video-tools-you-need': 'Tutti i potenti strumenti video di cui hai bisogno',
      'quickly-trim,-merge-reverse': '<b>Taglia, unisci, inverti, accelera o rallenta</b> i tuoi video in modo rapido.',
      'keep-audiences-engaged':
        '<b>Mantieni coinvolto il pubblico</b> aggiungendo testo, sottotitoli, musica, voiceover, ecc.',
      'animate-the-elements': '<b>Anima gli elementi</b> nel progetto e fai scorrere bene il tuo video.',
      'get-started': 'Inizia',
      'collaborate-and-share-seamlessly': 'Collabora e Condividi senza problemi',
      'collaborate-with-teammates-to-work-efficiently-and-build-branding-assets':
        '<b>Collabora</b> con il tuo team per lavorare in modo efficiente e creare risorse di branding.',
      'cloud-storage-gives-smooth-access-to-your-files-and-designs':
        '<b>L’archiviazione Cloud </b> ti consente di accedere senza problemi ai tuoi file e ai tuoi progetti.',
      'export-videos-up-to':
        'Esporta video fino a <b>4K di risoluzione</b> e condividili direttamente sulle piattaforme social.',

      'comment-title': 'Consigliato da milioni di creatori di contenuti',

      'read-full-story': 'Leggi l’intera storia',
      'case-studies-title': 'Articoli recenti',

      'float-entrance-1': 'Prova FlexClip per esplorare ',
      'float-entrance-2': 'strumenti di AI ',
      'float-entrance-3': 'e tonnellate di ',
      'float-entrance-4': 'risorse',

      'entrance-title': 'Il tuo viaggio verso video straordinari inizia qui',

      'AI-video-generator': 'Generatore Video IA',
      'generate-videos-from-your-prompt-article-or-URL': 'Genera video dal tuo messaggio, articolo, o URL',
      'generate-video': 'Genera Video',
      'AI-text-to-speech': 'IA Text to Speech',
      'turn-text-into-natural-sounding-voices': 'Trasforma il testo in voci dal suono naturale',
      'generate-speech': 'Genera Voce',
      'AI-script': 'Script Video IA',
      'generate-scripts-for-any-purpose': 'Genera script per qualsiasi utilizzo',
      'try-AI-script': 'Prova lo Script di IA',
      'AI-bg-remover': 'Rimozione dello sfondo IA',
      'easily-remove-background-from-videos-or-images': 'Rimuovi facilmente lo sfondo da video o immagini',
      'remove-background': 'Rimuovi Sfondo',
      'AI-auto-subtitle': 'Sottotitolo automatico AI',
      'automatically-identify-and-generate-subtitles-through-AI':
        "Identificazione e generazione automatica dei sottotitoli grazie all'intelligenza artificiale",
      'generate-subtitles': 'Genera Sottotitoli',
      'AI-translator': 'Traduttore AI',
      'create-multi-language-videos-with-ease': 'Creare video multilingue con facilità',
      'translate-now': 'Traduci ora',
      'videos-and-photos': 'Video & Foto',
      'music-and-sound-effects': 'Musica & Effetti audio',
      'text-presets': 'Preset di testo',
      'dynamic-elements': 'Elementi Dinamici',
      'effects-and-filters': 'Effetti & Filtri',
      transitions: 'Transizioni',
    },
    jp: {
      'home-banner-desc': 'AIツールと豊富なリソースで、賢く速く制作しましょう。',
      'create-by-template': 'テンプレートで作成',
      'trusted-by': 'TRUSTED BY',

      'home-filter-template-title': '<span>6000</span>以上のテンプレートですぐに制作を始めましょう',

      'template-bottom-text': 'すべて',

      'choiceness-title': 'AIツールを活用して、動画編集に革命',

      'resource-introduction-title': '豊富なリソースを活用して、動画コンテンツを充実させましょう',
      'resource-introduction-icon': ['ワンクリック適用', '商用利用可能', '常にアップデート'],

      'videos-and-photos': '動画と画像',
      'music-and-sound-effects': 'BGMと効果音',
      'text-presets': 'テキストプリセット',
      'dynamic-elements': 'ダイナミック要素',
      'effects-and-filters': 'エフェクトとフィルター',
      transitions: 'トランジション',

      'all-powerful-video-tools-you-need': '動画編集の最強ツールセット',
      'quickly-trim,-merge-reverse': '動画クリップを瞬時に <b>カット、結合、逆再生、早送り・スロー再生</b>できます。',
      'keep-audiences-engaged':
        'テキスト、字幕、音楽、ナレーションなどを追加して、<b>視聴者の興味を引き付けましょう。</b>',
      'animate-the-elements': 'プロジェクト内の<b>要素をアニメーション化</b>して、動画をスムーズにしましょう。',
      'get-started': '今すぐ始めましょう',
      'collaborate-and-share-seamlessly': 'チームでスムーズに共同作業',
      'collaborate-with-teammates-to-work-efficiently-and-build-branding-assets':
        'チームで効率良く<b>コラボレーション</b>して、ブランディングアセットを構築しましょう。',
      'cloud-storage-gives-smooth-access-to-your-files-and-designs':
        '<b>クラウドストレージ</b>で、ファイルやデザインにいつでもどこでもスムーズにアクセスできます。',
      'export-videos-up-to':
        '最大<b> 4K 解像度</b>でビデオを書き出し、ソーシャルプラットフォームにダイレクトに共有しましょう。',

      'comment-title': '数百万人のクリエイターに信頼されています',

      'read-full-story': '全文を読む',
      'case-studies-title': '最新記事',

      'float-entrance-1': 'FlexClipを試してみよう！',
      'float-entrance-2': 'AIツールと',
      'float-entrance-3': '豊富なリソースで ',
      'float-entrance-4': '動画編集を体験しましょう',

      'entrance-title': '素晴らしい動画はここから創造しましょう',

      'AI-video-generator': 'AI動画生成',
      'generate-videos-from-your-prompt-article-or-URL': '生成したい動画の説明を入力するか、URLを貼り付けてください',
      'generate-video': '動画を生成する',
      'AI-text-to-speech': 'テキストの読み上げ',
      'turn-text-into-natural-sounding-voices': 'テキストを自然な音声に変換します',
      'generate-speech': 'テキストを自然な音声に変換します',
      'AI-script': 'AI動画スクリプト',
      'generate-scripts-for-any-purpose': 'あらゆる用途のスクリプトを生成します',
      'try-AI-script': 'AIスクリプトを試してみてください',
      'AI-bg-remover': 'AIバックグラウンドリムーバー',
      'easily-remove-background-from-videos-or-images': '動画・画像の背景、サッと消せます',
      'remove-background': '背景削除',
      'AI-auto-subtitle': 'AI自動字幕起こし',
      'automatically-identify-and-generate-subtitles-through-AI': 'AIを使用して自動的に字幕を識別して生成します',
      'generate-subtitles': '字幕自動生成',
      'AI-translator': 'AI翻訳ツール',
      'create-multi-language-videos-with-ease': '多言語ビデオを簡単に作成できます',
      'translate-now': '今すぐ翻訳',
    },
    pt: {
      'home-banner-desc': 'Crie de forma mais inteligente e mais rápida com ferramentas de IA e inúmeros recursos.',
      'create-by-template': 'Experimente um modelo',
      'trusted-by': 'TRUSTED BY',

      'home-filter-template-title': 'Comece rápido com mais de <span>6000</span> modelos',

      'template-bottom-text': 'Ver tudo',

      'choiceness-title': 'Revolucione a criação de vídeos com ferramentas de IA',

      'resource-introduction-title': 'Melhore o nível do seu conteúdo de vídeo com recursos avançados',
      'resource-introduction-icon': ['Aplicação de um clique', 'Para uso comercial', 'Constantemente atualizado'],

      'videos-and-photos': 'Vídeos e fotos',
      'music-and-sound-effects': 'Música e efeitos sonoros',
      'text-presets': 'Predefinições de texto',
      'dynamic-elements': 'Elementos dinâmicos',
      'effects-and-filters': 'Efeitos e filtros',
      transitions: 'Transições',

      'all-powerful-video-tools-you-need': 'Todas as ferramentas de vídeo poderosas de que precisa',
      'quickly-trim,-merge-reverse': '<b>Corte, junte, inverta e acelere ou abrande </b> rapidamente os seus vídeos.',
      'keep-audiences-engaged':
        '<b>Mantenha o público interessado</b> adicionando texto, legendas, música, locuções, etc.',
      'animate-the-elements': '<b>Anime os elementos </b> no projeto e faça o seu vídeo fluir.',
      'get-started': 'Começar',
      'collaborate-and-share-seamlessly': 'Colabore e partilhe de forma perfeita',
      'collaborate-with-teammates-to-work-efficiently-and-build-branding-assets':
        '<b>Colabore</b> com colegas de equipa para trabalhar de forma eficiente e criar ativos de marca.',
      'cloud-storage-gives-smooth-access-to-your-files-and-designs':
        'O <b>armazenamento na nuvem </b> permite um acesso facilitado aos seus ficheiros e designs.',
      'export-videos-up-to':
        'Exporte vídeos até <b>4K de resolução</b> e partilhe diretamente nas plataformas de redes sociais.',

      'comment-title': 'Reconhecido por milhões de criadores de conteúdos',

      'read-full-story': 'Ler a história completa',
      'case-studies-title': 'Últimos artigos',

      'float-entrance-1': 'Experimente o FlexClip e explore ',
      'float-entrance-2': 'as suas ferramentas de IA ',
      'float-entrance-3': 'e inúmeros ',
      'float-entrance-4': 'recursos',

      'entrance-title': 'A sua jornada para criar vídeos fantásticos começa aqui',

      'AI-video-generator': 'Gerador de vídeos por IA',
      'generate-videos-from-your-prompt-article-or-URL': 'Gere vídeos a partir da sua instrução, artigo ou URL',
      'generate-video': 'Criar Vídeo',
      'AI-text-to-speech': 'Texto para fala com IA',
      'turn-text-into-natural-sounding-voices': 'Transforme texto em vozes com som natural',
      'generate-speech': 'Gerar Voz',
      'AI-script': 'Script de vídeo de IA',
      'generate-scripts-for-any-purpose': 'Gere scripts para qualquer finalidade',
      'try-AI-script': 'Criar Script',
      'AI-bg-remover': 'Removedor de fundo AI',
      'easily-remove-background-from-videos-or-images': 'Remova facilmente o fundo de vídeos ou imagens',
      'remove-background': 'Remover fundo',
      'AI-auto-subtitle': 'Legendas automáticas por IA',
      'automatically-identify-and-generate-subtitles-through-AI':
        'Identifique e gere legendas automaticamente através de IA',
      'generate-subtitles': 'Gere legendas',
      'AI-translator': 'Tradutor de IA',
      'create-multi-language-videos-with-ease': 'Crie facilmente vídeos em várias línguas',
      'translate-now': 'Traduzir agora',
    },
    tw: {
      'home-banner-desc': '運用AI工具與豐富資源高效地創作。',
      'create-by-template': '嘗試範本',
      'trusted-by': 'TRUSTED BY',

      'home-filter-template-title': '使用超過<span>6000</span>個範本來快速開始',

      'template-bottom-text': '全部範本',

      'choiceness-title': '使用AI工具革新影片創作',

      'resource-introduction-title': '使用豐富的資源來提升您的影片內容',
      'resource-introduction-icon': ['一鍵應用', '商業使用', '持續更新'],
      'videos-and-photos': '影片與照片',
      'music-and-sound-effects': '音樂與音效',
      'text-presets': '文字預設',
      'dynamic-elements': '動態元素',
      'effects-and-filters': '效果與濾鏡',
      transitions: '轉場效果',

      'all-powerful-video-tools-you-need': '您所需的所有強大影片工具',
      'quickly-trim,-merge-reverse': '快速<b>剪輯、合併、反轉，加速或放慢</b>您的影片片段。',
      'keep-audiences-engaged': '透過添加文字、字幕、音樂、旁白等方式<b>吸引觀眾的注意力。</b>',
      'animate-the-elements': '<b>將專案中的元素動畫化</b>讓您的影片更加流暢。',
      'get-started': '開始使用',
      'collaborate-and-share-seamlessly': '無縫合作與分享',
      'collaborate-with-teammates-to-work-efficiently-and-build-branding-assets':
        '與<b>團隊成員合作</b>以高效方式工作並建立品牌資產。',
      'cloud-storage-gives-smooth-access-to-your-files-and-designs': '<b>雲端儲存</b>讓您順暢地存取檔案和設計。',
      'export-videos-up-to': '將影片以<b>4K畫質</b>匯出並直接分享到社交平台。',

      'comment-title': '受到數百萬內容創作者信賴',

      'read-full-story': '閱讀完整故事',
      'case-studies-title': '最新文章',

      'float-entrance-1': '試試FlexClip來探索',
      'float-entrance-2': 'AI工具',
      'float-entrance-3': '和豐富的',
      'float-entrance-4': '資源',

      'entrance-title': '您的影片製作之旅從這裡開始',

      'AI-video-generator': 'AI影片生成器',
      'generate-videos-from-your-prompt-article-or-URL': '根據您的提示、文章或連結來生成影片',
      'generate-video': '生成影片',
      'AI-text-to-speech': 'AI文字轉語音',
      'turn-text-into-natural-sounding-voices': '用文字生成自然語音',
      'generate-speech': '生成語音',
      'AI-script': 'AI影片腳本',
      'generate-scripts-for-any-purpose': '為不同目的生成腳本',
      'try-AI-script': '試試AI影片腳本',
      'AI-bg-remover': 'AI背景去除器',
      'easily-remove-background-from-videos-or-images': '輕鬆從影片或圖像中移除背景',
      'remove-background': '移除背景',
      'AI-auto-subtitle': 'AI自動字幕',
      'automatically-identify-and-generate-subtitles-through-AI': '透過AI自動辨識與生成字幕',
      'generate-subtitles': '生成字幕',
      'AI-translator': 'AI 翻譯器',
      'create-multi-language-videos-with-ease': '輕鬆製作多語言影片',
      'translate-now': '即刻翻譯',
    },
    id: {
      'home-banner-desc': 'Buat lebih cerdas dan lebih cepat dengan alat bantu AI dan banyak sumber daya.',
      'create-by-template': 'Coba Templat',
      'trusted-by': 'TRUSTED BY',

      'home-filter-template-title': 'Mulai Cepat dengan <span>6000+</span> Templat',

      'template-bottom-text': 'Lihat Semua',

      'choiceness-title': 'Merevolusi Pembuatan Video dengan Alat AI',

      'resource-introduction-title': 'Tingkatkan Konten Video Anda dengan Sumber Daya yang Kaya',
      'resource-introduction-icon': ['Penerapan Sekali Klik', 'Untuk Penggunaan Komersial', 'Terus Diperbarui'],
      'videos-and-photos': 'Video & Foto',
      'music-and-sound-effects': 'Musik & Efek Suara',
      'text-presets': 'Preset Teks',
      'dynamic-elements': 'Elemen Dinamis',
      'effects-and-filters': 'Efek & Filter',
      transitions: 'Transisi',

      'all-powerful-video-tools-you-need': 'Semua Alat Video Canggih yang Anda Butuhkan',
      'quickly-trim,-merge-reverse':
        '<b>Potong, gabungkan, putar mundur, dan percepat atau perlambat klip</b> video Anda dengan cepat.',
      'keep-audiences-engaged':
        '<b>Buat penonton tetap terlibat</b> dengan menambahkan teks, subtitle, musik, sulih suara, dll.',
      'animate-the-elements': '<b>Animasikan elemen</b> dalam proyek dan buat alur video Anda.',
      'get-started': 'Memulai',
      'collaborate-and-share-seamlessly': 'Berkolaborasi dan Berbagi dengan Lancar',
      'collaborate-with-teammates-to-work-efficiently-and-build-branding-assets':
        '<b>Berkolaborasi dengan rekan tim</b> untuk bekerja secara efisien dan membangun aset merek.',
      'cloud-storage-gives-smooth-access-to-your-files-and-designs':
        '<b>Penyimpanan cloud</b> memberikan akses yang lancar ke berkas dan desain Anda.',
      'export-videos-up-to': 'Ekspor video hingga <b>resolusi 4K</b> dan langsung bagikan ke platform sosial.',

      'comment-title': 'Dipercaya oleh Jutaan Orang',

      'read-full-story': 'Baca cerita selengkapnya',
      'case-studies-title': 'Artikel Terbaru',

      'float-entrance-1': 'Coba FlexClip untuk mengeksplorasi ',
      'float-entrance-2': 'Alat AI ',
      'float-entrance-3': 'dan ton ',
      'float-entrance-4': 'SUMBER DAYA',

      'entrance-title': 'Perjalanan Anda menuju video yang menakjubkan dimulai di sini',

      'AI-video-generator': 'AI Penghasil Video',
      'generate-videos-from-your-prompt-article-or-URL': 'Hasilkan video dari perintah, artikel, atau URL Anda',
      'generate-video': 'Hasilkan Video',
      'AI-text-to-speech': 'AI Teks jadi Ucapan',
      'turn-text-into-natural-sounding-voices': 'Ubah teks menjadi suara yang terdengar alami',
      'generate-speech': 'Hasilkan Ucapan',
      'AI-script': 'AI Skrip',
      'generate-scripts-for-any-purpose': 'Hasilkan skrip untuk tujuan apa pun',
      'try-AI-script': 'Coba AI Skrip',
      'AI-bg-remover': 'AI Penghapus Latar Belakang',
      'easily-remove-background-from-videos-or-images': 'Hapus latar belakang dari video atau gambar dengan mudah',
      'remove-background': 'Hapus Latar Belakang',
      'AI-auto-subtitle': 'AI Subtitle Otomatis',
      'automatically-identify-and-generate-subtitles-through-AI':
        'Identifikasi dan hasilkan subtitle secara otomatis melalui AI',
      'generate-subtitles': 'Hasilkan Subtitle',
      'AI-translator': 'AI Penerjemah',
      'create-multi-language-videos-with-ease': 'Buat video multi-bahasa dengan mudah',
      'translate-now': 'Terjemahkan Sekarang',
    },
    ar: {
      'home-banner-desc':
        'أنشئ مقاطع فيديو بطريقة أكثر ذكاء وسرعة باستخدام أدوات الذكاء الاصطناعي ومجموعة ضخمة من الموارد.',
      'create-by-template': 'جرب قالب',
      'trusted-by': 'موثوق به من قبل',

      'home-filter-template-title': 'أبدا بسرعة مع أكثر من <span>6000+</span> قالب',

      'template-bottom-text': 'عرض الكل',

      'choiceness-title': 'أحدث ثورة في إنشاء مقاطع الفيديو باستخدام أدوات الذكاء الاصطناعي',

      'resource-introduction-title': 'ارتقِ بمحتوى الفيديو الخاص بك باستخدام العديد من الموارد المتنوعة   ',
      'resource-introduction-icon': ['التطبيق عن طريق نقرة واحدة', 'مناسب للاستخدام التجاري', 'يتم التحديث بشكل مستمر'],
      'videos-and-photos': 'الفيديوهات والصور',
      'music-and-sound-effects': 'الموسيقى والمؤثرات الصوتية',
      'text-presets': 'قوالب النصوص',
      'dynamic-elements': 'العناصر الديناميكية',
      'effects-and-filters': 'التاثيرات والفلاتر',
      transitions: 'الانتقالات',

      'all-powerful-video-tools-you-need': 'كل أدوات الفيديو القوية التي تحتاجها',
      'quickly-trim,-merge-reverse': 'سرعة <b>قص ودمج وعكس وتسريع أو تبطيء</b> مقاطع الفيديو الخاصة بك',
      'keep-audiences-engaged':
        '<b>اجذب انتباه الجمهور</b> عن طريق إضافة نص، وترجمات، وموسيقى، وتعليق صوتي، وما إلى ذلك.',
      'animate-the-elements': '<b>حرك العناصر</b> بمشروعك لجعل الفيديو يتدفق بانسيابية',
      'get-started': 'ابدأ الآن',
      'collaborate-and-share-seamlessly': 'التعاون ومشاركة المحتوى بسلاسة',
      'collaborate-with-teammates-to-work-efficiently-and-build-branding-assets':
        '<b>تعاون</b> مع فريقك بكفاءة وقم بإنشاء أصول العلامة التجارية.',
      'cloud-storage-gives-smooth-access-to-your-files-and-designs':
        '<b>التخزين السحابي</b> يمنحك وصولاً سلسًا إلى ملفاتك وتصاميمك.',
      'export-videos-up-to': 'صدر فيديوهاتك <b>بدقة تصل إلى 4k</b> وشاركها مباشرة على منصات التواصل الاجتماعي',

      'comment-title': 'موثوق به من قبل ملايين',
      'read-full-story': 'اقرأ القصة كاملة',
      'float-entrance-1': 'جرب واستكشف ما يقدمه FlexClip',
      'float-entrance-2': 'أدوات الذكاء الاصطناعي',
      'float-entrance-3': 'وكمية هائلة من',
      'float-entrance-4': 'المصادر',
      'entrance-title': 'رحلتك لصنع فيديوهات مذهلة تبدأ من هنا',

      'AI-video-generator': 'مولد الفيديو بالذكاء الاصطناعي',
      'generate-videos-from-your-prompt-article-or-URL':
        'أنشئ فيديوهات باستخدام المطالبات النصية الخاصة بك، أو المقالات، أو الروابط.',
      'generate-video': 'إنشاء فيديو',
      'AI-text-to-speech': 'تحويل النص إلى كلام بالذكاء الاصطناعي',
      'turn-text-into-natural-sounding-voices': 'تحويل النص إلى أصوات طبيعية',
      'generate-speech': 'توليد الكلام',
      'AI-script': 'النصوص المعتمدة على الذكاء الاصطناعي',
      'generate-scripts-for-any-purpose': 'توليد النصوص لأي غرض',
      'try-AI-script': 'جرب برنامج نص برمجي',
      'AI-bg-remover': 'أداة إزالة الخلفية بواسطة الذكاء الاصطناعي',
      'easily-remove-background-from-videos-or-images': 'قم بإزالة الخلفية بسهولة من مقاطع الفيديو أو الصور',
      'remove-background': 'إزالة الخلفية',
      'AI-auto-subtitle': 'أداة الترجمة التلقائية باستخدام الذكاء الإصطناعي',
      'automatically-identify-and-generate-subtitles-through-AI':
        'التعرف على النصوص وتوليد الترجمة تلقائيًا باستخدام الذكاء الاصطناعي.',
      'generate-subtitles': 'إنشاء ترجمات',
      'AI-translator': 'الترجمة باستخدام الذكاء الاصطناعي',
      'create-multi-language-videos-with-ease': 'تسريع أو إبطاء سرعة الفيديو تدريجيًا',
      'translate-now': 'ترجم الآن',
    },
  },
};
