import Style from './home.module.less';
import React, { Component } from 'react';
import classname from 'classnames';
// 直接引入的组件
import FJButtonBrick from '../../components/ButtonBrick/FJButtonBrick';
import FJCssSwiper from '../../components/homeRevision/FJCssSwiper/FJCssSwiper';
import FJKeepBgHeight from '../../components/homeRevision/FJKeepBgHeight/FJKeepBgHeight';
import FJTemplateItem from '../../components/homeRevision/FJTemplateItem/FJTemplateItem';
import FJTabs from '../../components/homeRevision/FJTabs';
import FJJSSwiper from '../../components/homeRevision/FJJSSwiper/FJJSSwiper2';
import FJSvg from '../../components/FJSvg/FJSvg'
import FJRatingSite from '../../components/homeRevision/FJRatingSite/FJRatingSite';
import SwiperPagination from '../../components/homeRevision/SwiperPagination/SwiperPagination';
import SwiperNavigation from '../../components/homeRevision/SwiperNavigation/SwiperNavigation';
// 使用到的素材
import arrowSvg from '../../src/assets/icon/arrow.svg';
import QuoteSvg from '../../src/assets/icon/quote.svg';
// 需要用到的工具函数
import FJLocalStore from '../../src/store/FJLocalStore';
import FJSrcUtil from '../../src/util/FJUtil';
import FJUtil from '../../util/FJUtil';
import FJConfig from '../../src/config/FJConfig';
import Data from '../../src/data/FJHomeRevision';
import FJPagesDataStore from '../../src/store/FJPagesDataStore';
import FJNetworkStore from '../../src/store/FJNetworkStore';
// 动态引入的组件
import dynamic from 'next/dynamic';
const CaseStudiesItem = dynamic(() => import('../../components/homeRevision/SwiperArticle/CaseStudiesItem'))
const FJEntrance = dynamic(() => import('../../components/homeRevision/FJEntrance/FJEntrance'))
const FJFeatureWidthIcon = dynamic(() => import('../../components/homeRevision/FJFeatureWithIcon/FJFeatureWidthIcon'))
const FJVideoBrick = dynamic(() => import('../../components/VideoBrick/FJVideoBrick'))
const FJVideoFullScreenPreview = dynamic(
  () => import('../../components/FJVideoFullScreenPreview/FJVideoFullScreenPreview'),
  { ssr: false }
);
const FJTemplateListContainer = dynamic(() => import('../../components/FJCreate/FJTemplateList/FJTemplateListContainer'));
const FJChoiceness = dynamic(() => import('../../components/homeRevision/FJChoiceness/FJChoiceness'),)
const FJResourceIntroduction = dynamic(() => import('../../components/homeRevision/FJResourceIntroduction/FJResourceIntroduction'),)

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entranceController1: false,
      entranceController2: false,
      tabActive: 0,
      displayTemplateList: {},

      // 全屏预览视频参数
      fullPreviewVideoSrc: '',
      currentItemInfo: null,

      isMobile: false,

      // 获取用户是否接受了cookie协议，没接受的情况下，就不显示快速入口,接受了，localstorage中就会对一个字段，且根据页面逻辑展示快速入口
      belongAcceptCookieHidden: true,

      articleBasicWidth: 414,

      displayData1: [],
      displayData2: [],
    };

    this.templateBoxRef = React.createRef();
    this.shareToolTimer = null;
    this.entranceTimer = null;
    this.componentRefreshKey = React.createRef();
  }

  __drawHead() {
    let URL1 = FJSrcUtil.getCurrentUrl() + '/template/';
    let URL2 = FJSrcUtil.getCurrentUrl() + '/editor/app?ratio=landscape';
    let URL3 = FJSrcUtil.getCurrentUrl() + '/ai?tools=image';
    const bgImg = 'https://resource.flexclip.com/pages/home/home-banner.webp';
    const content =
      <>
        <h1 className={Style.banner_title} dangerouslySetInnerHTML={{ __html: FJLocalStore._('home-banner-title') }} />
        <p className={Style.banner_desc}>{FJPagesDataStore.getLocalizationObtainingData('home-banner-desc')}</p>
        <div className={classname('observerDom', Style.banner_btn_box)} data-observer-type='entranceController1'>
          {this.state.isMobile ?
            <>
              <FJButtonBrick
                URL={URL1}
                onClick={() => {
                  FJUtil.ealog('mobile try flexclip ai click');
                  window.location.href = URL3;
                }}
                className={Style.templateBtn}
                type='other'
              >
                <p>
                  <span>{FJLocalStore._('try-flexclip-ai')}</span>
                </p>
              </FJButtonBrick>
              <FJButtonBrick
                URL={URL2}
                onClick={() => {
                  FJUtil.ealog('home jump', {
                    'click name': 'creat by template',
                  });
                  window.location.href = URL1;
                }}
                className={Style.scratchBtn}
                type='other'
              >
                <p>
                  <span>{FJLocalStore._('create-by-template')}</span>
                </p>
              </FJButtonBrick>
            </>
            :
            <>
              <FJButtonBrick
                URL={URL1}
                onClick={() => {
                  FJUtil.ealog('home jump', {
                    'click name': 'creat by template',
                  });
                  window.location.href = URL1;
                }}
                className={Style.templateBtn}
                type='other'
              >
                <p>
                  <span>{FJLocalStore._('create-by-template')}</span>
                </p>
              </FJButtonBrick>
              <FJButtonBrick
                URL={URL2}
                onClick={() => {
                  FJUtil.ealog('home start now click', {
                    'button location': 'start from scratch',
                  });
                  window.location.href = URL2;
                }}
                className={Style.scratchBtn}
                type='other'
              >
                <p>
                  <span>{FJLocalStore._('start-from-scratch')}</span>
                </p>
              </FJButtonBrick>
            </>
          }
        </div>
        <div
          className={classname('keepBgHeightTarget', Style.video_box)}
          onClick={() => {
            window.open('https://youtu.be/6WJBcoh8pR8');
          }}
        >
          <video
            key={'bannerVideo' + this.componentRefreshKey.current}
            loop={true}
            autoPlay={Boolean(this.componentRefreshKey.current)}
            muted={true}
            playsInline={true}
            preload={!this.componentRefreshKey.current ? 'metadata' : 'auto'}
            className={Style.video}
            src={
              FJConfig.staticResourceServerAddress + `/pages/home/video/banner-compress3.mp4?v=${FJConfig.buildVersion}`
            }
            width={1250}
            height={703}
          />
        </div>
        <div className={Style.trust_text}>{FJPagesDataStore.getLocalizationObtainingData('trusted-by')}</div>

        <FJCssSwiper />
      </>

    return <>
      {this.state.isMobile ?
        <FJKeepBgHeight bgImg={bgImg} className={Style.headLayoutBox} >
          {content}
        </FJKeepBgHeight> :
        (<div className={Style.headLayoutBox}>
          <div className={Style.polygons}>
            <div className={`${Style.polygon_1} ${Style.polygon}`}></div>
            <div className={`${Style.polygon_2} ${Style.polygon}`}></div>
            <div className={`${Style.polygon_3} ${Style.polygon}`}></div>
            <div className={`${Style.polygon_4} ${Style.polygon}`}></div>
          </div>
          <div className={Style.content}>
            {content}
          </div>
        </div>)}
    </>
  }

  jumpToEditWithTemplateId(item) {
    window.location.href = `${FJSrcUtil.getCurrentUrl()}/editor/app?template_id=${item.key}`;
  }

  __drawTemplate() {
    let changeTemplateData = index => {
      const displayList = Array.isArray(this.props.homeTemplate) ? this.props.homeTemplate[index] : [];

      let list = (displayList && displayList['cate_data']) || [];

      let displayData1 = list.slice(0, Math.ceil(list.length / 2));
      let displayData2 = list.slice(Math.ceil(list.length / 2) + 1, list.length).reverse();
      this.setState({
        tabActive: index,
        displayTemplateList: displayList,
        displayData1,
        displayData2,
      });
    };

    let options = Array.isArray(this.props.homeTemplate) ? this.props.homeTemplate.map(item => item['cate_name']) : [];

    let URL = FJSrcUtil.getCurrentUrl() + '/template/';
    let __drawTemplateItem = (item, index, childAllowClick) => {
      return (
        <FJTemplateItem
          key={item.key + String(index)}
          category={options[this.state.tabActive]}
          slotProps={item}
          fullPreview={this.changePreviewVideoSrc}
          textLeft={FJLocalStore._('Preview')}
          textRight={FJLocalStore._('Customize')}
          customizeClick={this.jumpToEditWithTemplateId}
          childAllowClick={childAllowClick}
        />
      );
    };
    return (
      <section className={Style.templateBox}>
        <h2
          className={Style.templateTitle}
          dangerouslySetInnerHTML={{
            __html: FJPagesDataStore.getLocalizationObtainingData('home-filter-template-title'),
          }}
        />
        <div className={Style.tabsLayoutBox}>
          {options.length > 0 && (
            <FJTabs
              options={options}
              boxClass={Style.tabBox}
              itemClass={Style.tabItem}
              activeClass={Style.tabItemActive}
              tabClick={changeTemplateData}
            />
          )}
        </div>
        <div className='observerDom' data-root='#share-tools-root' data-observer-type='1' ref={this.templateBoxRef}>
          {this.state.displayData1.length > 0 && (
            <FJJSSwiper key={this.state.tabActive} list={this.state.displayData1} defaultSlot={__drawTemplateItem} />
          )}
          {this.state.displayData2.length > 0 && (
            <FJJSSwiper
              key={this.state.tabActive + '1'}
              list={this.state.displayData2}
              direction='reverse'
              defaultSlot={__drawTemplateItem}
            />
          )}
        </div>
        <FJButtonBrick
          URL={URL}
          onClick={() => {
            FJUtil.ealog('home jump', {
              'click name': 'see all',
            });
            window.location.href = URL;
          }}
          className={Style.templateBottomBtn}
          type='other'
        >
          {FJPagesDataStore.getLocalizationObtainingData('template-bottom-text')}
          <FJSvg className={Style.templateBtnSvg} src={arrowSvg} />
        </FJButtonBrick>
      </section>
    );
  }

  __drawFeature() {
    const data = FJPagesDataStore.getLocalizationDataByKey('UIDataInfo', 'feature');

    return (
      <section key={'feature' + this.componentRefreshKey.current} className={Style.featureContainer}>
        {data.map((item, index) => {
          const leftAnimationKeyframe = [
            { transform: `translateX(${index % 2 === 1 ? '20px' : '-20px'})`, opacity: 0, visibility: 'hidden' },
            { transform: 'translateX(0)', opacity: 1, visibility: 'visible' },
          ];

          const rightAnimationKeyframe = [
            { transform: `translateX(${index % 2 === 1 ? '-20px' : '20px'})`, opacity: 0, visibility: 'hidden' },
            { transform: 'translateX(0)', opacity: 1, visibility: 'visible' },
          ];
          return (
            <FJFeatureWidthIcon
              key={'featureIndex' + index}
              ealog={{
                eventType: 'home start now click',
                eventData: { 'button location': `start now（feature${index + 1}）` },
              }}
              data={item}
              isReverse={index % 2 === 1}
              className={index > 0 ? Style.featureItemStyle : Style.featureItemStyleTop}
              target='_self'
              domPercent={{ left: '32%', right: '56%' }}
              imgClassName={Style.featureWidthIconImg}
              useAnimation={!this.state.isMobile}
              leftAnimationKeyframe={leftAnimationKeyframe}
              rightAnimationKeyframe={rightAnimationKeyframe}
            />
          );
        })}
      </section>
    );
  }

  __drawTools() {
    let videoToolList = { ...Data.videoToolList };
    if (FJLocalStore.currentTag() === 'ar') {
      videoToolList.toolList = videoToolList.toolList.map(item => {
        return {
          ...item,
          hasLocalization: false,
        };
      });
    }
    return (
      <FJVideoBrick
        key={'tool' + this.componentRefreshKey.current}
        videoToolList={videoToolList}
        style={{ backgroundColor: '#ffffff' }}
        themes={'line'}
        addEalog={{ eventType: 'home jump' }}
        useAnimation={!this.state.isMobile}
      />
    );
  }

  __drawComment() {
    const { commentData, language } = this.props;
    if (!commentData) {
      return;
    }
    const list = commentData && Array.isArray(commentData.global) ? commentData.global : [];
    const clientData = commentData && Array.isArray(commentData.comment) ? commentData.comment : [];

    function drawSwiperSlideItem(item) {
      return (
        <div className={Style.commentLeftContainer}>
          <span className={Style.commentType}>{item.number}</span>
          <p className={Style.comment}>{item.desc}</p>
        </div>
      );
    }

    function drawSwiperSlideRightItem(item) {
      function rightClick(url) {
        window.open(url);
      }

      return (
        <div className={Style.commentRightItem}>
          <p dangerouslySetInnerHTML={{ __html: item.desc }} />
          <div className={Style.feedBackProvider}>
            <div className={Style.feedBackProviderLeft}>
              <img src={item.avatar} width={60} height={60} alt={item.name + ' avatar'} loading='lazy' />
              <div className={Style.feedBackProviderLeftText}>
                <div>{item.name}</div>
                <span>{item.position}</span>
              </div>
            </div>

            {item.link && (
              <div
                className={Style.feedBackProviderRight}
                onClick={() => {
                  rightClick(item.link);
                }}
              >
                <span>{FJPagesDataStore.getLocalizationObtainingData('read-full-story')}</span>
                <FJSvg src={arrowSvg} />
              </div>
            )}
          </div>
        </div>
      );
    }

    function __drawCommentRight() {
      return (
        <SwiperNavigation
          drawSwiperSlideItem={drawSwiperSlideRightItem}
          clientData={clientData}
          delay={5000}
          swiperStyle={{ height: '100%' }}
        />
      );
    }

    function __drawCommentTitle() {
      const title = FJPagesDataStore.getLocalizationObtainingData('comment-title');
      return FJSrcUtil.__drawHTag('h2', title, Style.commentTitle);
    }

    return (
      <section className={classname(Style.commentBox, 'observerDom')} data-observer-type='commentAnimation'>
        {__drawCommentTitle()}
        <div className={Style.commentLayoutBox}>
          <div className={classname(Style.commentLeft, !this.state.isMobile ? Style.commentLeftAnimationStart : '')}>
            <img
              src='https://resource.flexclip.com/pages/home/bg-commentLeft.webp'
              className={Style.commentLeftBg}
              width={636}
              height={390}
              alt='comment bg'
              loading='lazy'
            />
            <SwiperPagination drawSwiperSlideItem={drawSwiperSlideItem} clientData={list} delay={3000} />
          </div>
          <div className={classname(Style.commentRight, !this.state.isMobile ? Style.commentRightAnimationStart : '')}>
            <FJSvg src={QuoteSvg} className={Style.commentRightTopIcon} />
            {__drawCommentRight()}
          </div>
        </div>

        <div className={Style.ratingSite}>
          <FJRatingSite language={language} />
        </div>
      </section>
    );
  }

  // 推文板块
  __drawCaseStudies = () => {
    if (FJLocalStore.currentTag() === 'ar') {
      return null;
    }
    const { isMobile } = this.state;

    function drawSwiperListItem(style, data, index, currentPage, caseStudiesDrawTime) {
      return (
        <CaseStudiesItem
          data={data}
          index={index}
          style={style}
          currentPage={currentPage}
          useAnimation={!isMobile && caseStudiesDrawTime < 2}
        />
      );
    }

    const { articleData } = this.props;
    let carouselListStyle = { maxWidth: '1310px', margin: '0 auto' };
    return (
      <section className={Style.caseStudiesContainer}>
        <h2 className={Style.caseStudiesTitle}>
          {FJPagesDataStore.getLocalizationObtainingData('case-studies-title')}
        </h2>
        <FJTemplateListContainer
          containerStyle={carouselListStyle}
          basicWidth={this.state.articleBasicWidth}
          maxMargin={30}
          marginTop={30}
          data={articleData}
          numberOfRows={1}
          autoHeight={false}
          drawItem={drawSwiperListItem}
          modules={['Pagination']}
          swiperStyle={{ height: '100%' }}
        />
      </section>
    );
  };

  // 改变分享组件的显示、隐藏
  changeShareToolDisplay = () => {
    clearTimeout(this.shareToolTimer);

    this.shareToolTimer = setTimeout(() => {
      const fixedElementContainer = document.getElementById('share-tools-root');
      const fixedElement = fixedElementContainer.firstElementChild;

      const fixedTop = 204;
      const fixedHeight = 273;
      const elementTop = this.templateBoxRef.current.getBoundingClientRect().top;
      const elementHeight = this.templateBoxRef.current.offsetHeight;

      // 检查元素是否与fixed元素上边界交叉
      const intersectsTop = elementTop < fixedTop + fixedHeight;
      // elementTop >

      // 检查元素是否与fixed元素下边界交叉
      const intersectsBottom = elementTop + elementHeight > fixedTop;

      // intersectsTop && intersectsBottom 结果都为true时，元素与fixed元素不交叉！
      if (!(intersectsTop && intersectsBottom)) {
        fixedElement.style.visibility = 'visible';
      } else {
        fixedElement.style.visibility = 'hidden';
      }

      return intersectsTop && intersectsBottom;
    }, 100);
  };

  // 给h1标题添加动画
  changeH1Opacity(target) {
    target.classList.add(Style.banner_title_animation);
  }

  // 给comment左右两部分添加动画
  addCommentAnimation(dom, observer) {
    // 移动端不需要添加动画
    if (this.state.isMobile) {
      return;
    }
    const divChildList = dom.querySelectorAll(':scope > div');

    const commentChildList = divChildList[0].querySelectorAll(':scope > div');
    commentChildList[0].classList.add(Style.commentAnimationEnd);
    commentChildList[1].classList.add(Style.commentAnimationEnd);

    observer.unobserve(dom);
  }

  // 观察器对象符合观察条件时，执行的事件
  targetObserverFun = (entry, observer) => {
    switch (entry.target.dataset.observerType) {
      case 'entranceController1':
        // 控制底部浮动入口显示、隐藏
        this.setState({
          entranceController1: entry.isIntersecting,
        });
        break;
      case 'entranceController2':
        // 控制底部浮动入口显示、隐藏
        // 滑动超过最大高度时，需要将快速入口隐藏,因为这个元素是最后一个元素，往下就是其他内容，其他内容之上不需要该模块
        if (!entry.isIntersecting && entry.boundingClientRect.top < 0) {
          this.setState({
            entranceController2: true,
          });
        } else {
          this.setState({
            entranceController2: entry.isIntersecting,
          });
        }
        break;
      case '1':
        const fixedElementContainer = document.getElementById('share-tools-root');
        if (!fixedElementContainer) {
          return;
        }
        FJUtil.addEvent(window, 'scroll', this.changeShareToolDisplay);
        break;
      // h1标题动画
      case '2':
        if (entry.isIntersecting) {
          this.changeH1Opacity(entry.target);
        }
        break;
      case 'commentAnimation':
        if (entry.isIntersecting) {
          this.addCommentAnimation(entry.target, observer);
        }
        break;
    }
  };

  changePreviewVideoSrc = (itemInfo, src) => {
    this.setState({
      currentItemInfo: itemInfo || {},
      fullPreviewVideoSrc: src || '',
    });
  }

  observer = null;

  updateUI = () => {
    this.setState({
      articleBasicWidth: window.innerWidth < 1465 ? (window.innerWidth < 1150 ? 414 : 350) : 414,
    });
  };

  componentDidMount() {
    FJUtil.ealog('home page visit');

    const displayList = Array.isArray(this.props.homeTemplate) ? this.props.homeTemplate[0] : [];
    let list = (displayList && displayList['cate_data']) || [];

    let displayData1 = list.slice(0, Math.ceil(list.length / 2));
    let displayData2 = list.slice(Math.ceil(list.length / 2) + 1, list.length).reverse();
    this.setState({
      displayData1,
      displayData2,
    });

    if (!this.componentRefreshKey.current || this.componentRefreshKey.current === 0) {
      this.componentRefreshKey.current = 1;
    }

    // 现行判断是否是移动端，是移动端就不显示快速入口
    const isMobile = FJSrcUtil.isMobile();

    if (isMobile) {
      this.setState({
        isMobile: FJSrcUtil.isMobile(),
      });
    }

    // 创建一个 IntersectionObserver 实例
    const observer = new IntersectionObserver(
      entries => {
        // entries 是一个包含所有观察的目标元素及其交叉信息的数组
        entries.forEach(entry => {
          this.targetObserverFun(entry, observer);
        });
      },
      {
        root: null, // 观察的根元素为视窗
      },
    );

    const observerDomList = document.querySelectorAll('.observerDom');

    // 遍历每个元素，观察它们
    observerDomList.forEach(element => {
      observer.observe(element);
    });

    const entranceDom = document.getElementsByClassName(Style.entranceContainer);
    if (entranceDom && entranceDom[0]) {
      observer.observe(entranceDom[0]);
    }

    const shareTools = document.getElementById('#share-tools-root');
    shareTools && observer.observe(shareTools);

    this.templateBoxRef.current && observer.observe(this.templateBoxRef.current);

    // 使用观察器观察video出现时才加载对应资源
    this.observer = FJUtil.videoVisibleLoad();

    // 增添动画
    FJUtil.addEvent(window, 'scroll', this.blockMoveToCenter);
    // 为了能在1280下显示三篇推文
    this.updateUI();
    FJUtil.addEvent(window, 'resize', this.updateUI);
  }

  componentWillUnmount() {
    this.observer && this.observer.disconnect();
    FJUtil.removeEvent(window, 'scroll', this.blockMoveToCenter);
    FJUtil.removeEvent(window, 'scroll', this.changeShareToolDisplay);
  }

  render() {
    return (
      <div className={Style.home}>
        {this.__drawHead()}
        {this.__drawTemplate()}
        <section key={'choiceness' + this.componentRefreshKey.current} className={Style.choicenessBox}>
          <FJChoiceness
            data={{
              title: FJPagesDataStore.getLocalizationObtainingData('choiceness-title'),
              tools: FJPagesDataStore.getLocalizationDataByKey('UIDataInfo', 'choiceness-tools'),
            }}
            useAnimation={!this.state.isMobile}
          />
        </section>
        <section className={Style.resourceLayoutBox}>
          <FJResourceIntroduction
            title={FJPagesDataStore.getLocalizationObtainingData('resource-introduction-title')}
            iconList={FJPagesDataStore.getLocalizationObtainingData('resource-introduction-icon')}
            list={FJPagesDataStore.getLocalizationDataByKey('UIDataInfo', 'resource-introduction-list')}
            useAnimation={!this.state.isMobile}
          />
        </section>
        {this.__drawFeature()}
        {this.__drawTools()}
        {this.__drawComment()}
        {this.__drawCaseStudies()}
        <section className={classname('observerDom', Style.entranceContainer)} data-observer-type='entranceController2'>
          <FJEntrance
            ealog={{
              eventType: 'home start now click',
              eventData: { 'button location': 'start now（bottom）' },
            }}
            title={FJPagesDataStore.getLocalizationObtainingData('entrance-title')}
          />
        </section>

        {/*全屏预览视频组件*/}
        {this.state.fullPreviewVideoSrc &&
          <FJVideoFullScreenPreview src={this.state.fullPreviewVideoSrc} close={this.changePreviewVideoSrc}>
            <div
              className={Style.previewBtn}
              onClick={() => {
                this.jumpToEditWithTemplateId(this.state.currentItemInfo);
              }}
            >
              {FJLocalStore._('Customize')}
            </div>
          </FJVideoFullScreenPreview>}
      </div>
    );
  }
}

export async function getServerSideProps(context) {
  let { locale } = context;

  // let result = await FJNetworkStore.getHomeTempalte(locale);
  // let commentData = await FJNetworkStore.getCommentData(locale);
  // let articleData = await FJNetworkStore.getArticle('home', locale);

  const [templateData, commentData, articleData] = await Promise.all([
    FJNetworkStore.getHomeTempalte(locale),
    FJNetworkStore.getCommentData(locale),
    FJNetworkStore.getArticle('home', locale)
  ]);

  let props = {
    language: locale,
    homeTemplate: templateData?.data,
    commentData: commentData || {},
    articleData: articleData?.data || [],
  };
  return {
    props,
  };
}

export default function MovieMaker(props) {
  const { language, homeTemplate, commentData, articleData } = props;

  FJLocalStore.setCurrentTag(language);
  FJPagesDataStore.setData(Data);
  return <Home language={language} homeTemplate={homeTemplate} commentData={commentData} articleData={articleData} />;
}
